import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import * as staffClasses from "../Staff.module.css"
import {
  imageSerializer, createImgBuilder, youtubeSerializer, blockSerializerWithAlignment, sanityClient,
} from 'jrts-sanity';
import { Card, CardBody, CardFooter, Flex, Heading, Image, Stack, useMediaQuery } from '@chakra-ui/react';

const client = sanityClient('zbi98nq2', 'production', 'true');

const builder = createImgBuilder(client);

function urlFor(source) {
  return builder.image(source)
}

const serializers = {
  types: {
    imageBlockContent: (props) => imageSerializer(props, builder),
    youtube: ({ node }) => youtubeSerializer(node),
    block: blockSerializerWithAlignment,
  },
};

function StaffMember(props) {
  const [isLessThan500] = useMediaQuery('(max-width: 500px)')
  return (
    <Flex w='100%'>
    {props.image && props.image?.asset?.gatsbyImageData && !isLessThan500 && (
          <Image
          mr={5}
          boxShadow={'0px 0px 0px 3px #acdd6f, 0px 0px 10px 0px rgba(0,0,0,0.25)'}
          alt=""
          className={`${staffClasses.StaffThumbnailImage} img-thumbnail rounded-circle`}
          src={urlFor(props.image)
            .auto("format")
            .height(200)
            .width(200)
            .fit("crop")
            .url()}
        />
    )}
    <Card 
      minHeight='150px'
      flex={1}
    direction={{ base: 'row' }}
    overflow='hidden'
    borderRadius={'8px'} mb={'48px'} id={props.name}>
      <Stack w='100%'>
        <CardBody justifyContent={{ base: 'space-evenly', sm: 'center' }} display='flex' alignItems={{ base: 'center', sm: 'flex-start' }} flexDirection={{base: 'row', sm: 'column'}}>
        {props.image && props.image?.asset?.gatsbyImageData && isLessThan500 && (
            <Image
            boxShadow={'0px 0px 0px 3px #acdd6f, 0px 0px 10px 0px rgba(0,0,0,0.25)'}
            alt=""
            className={`${staffClasses.StaffThumbnailImage} img-thumbnail rounded-circle`}
            src={urlFor(props.image)
              .auto("format")
              .height(200)
              .width(200)
              .fit("crop")
              .url()}
          />
      )}
          <Heading color={'gray.800'} pl={{base: '8px', sm: 0}} as='h2' size='xl'>{props.name}</Heading>
          {!isLessThan500 && <BlockContent blocks={props.bio} serializers={serializers} />}
        </CardBody>
        
        {isLessThan500 &&<CardFooter justifyContent='center'><BlockContent blocks={props.bio} serializers={serializers} />
        </CardFooter>}
      </Stack>
    </Card>
    </Flex>
  );
}

export default StaffMember;
