import React, { useEffect, useRef, useState } from 'react';
import './About.module.css';
import ProfServicesRow from './ProfServicesRow/ProfServicesRow';
import Specials from '../Specials/Specials';
import Laser from './Laser/Laser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { Box, Button, Flex, SlideFade, Text } from '@chakra-ui/react';
// import InstagramFeed from '../InstagramFeed/InstagramFeed'

function About({ data }) {
  const imageNode = data?.allSanityHomePage?.edges[0];
  const salesImage = data?.sanityHomePage?.saleImage?.asset?.gatsbyImageData

  const buttonEl = useRef(null);
  const [run, setRun] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const el = buttonEl?.current;
      if (el) {
        if (el.getBoundingClientRect().bottom - window.innerHeight <= 0 && run !== true) {
          setRun(true);
        }
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [run]);

  return (
    <>
    <div className="container-fluid">
      <Laser image={imageNode ? imageNode.node?.homeHeader : ''} />
      <ProfServicesRow
        image={imageNode ? imageNode?.node?.professionalServicesImage : ''}
      />
      {/* <InstagramFeed /> */}
    </div>
      <Flex flexDirection={{
        'base': 'column',
        md: 'row'
      }} flexWrap={'wrap'} py={'32px'} bg='gray.800' px={{
        base: '16px',
        lg: '85px'
      }}>
        <Flex width={{
        'base': 'auto',
        md: '50%'
      }} alignItems='center'>
          <Text textAlign={{
            base: 'center',
            md: 'left'
          }} fontSize='20px' mb={0} fontWeight={300} color='white'>
            Providing laser and spa services to the beautiful people of Spokane
            for more than {new Date().getUTCFullYear() - 2004} years.
          </Text>
        </Flex>
        <Flex width={{
        'base': 'auto',
        md: '50%'
      }} alignItems='center' justifyContent='center' pl={{
        base: '0',
        md: 8
      }}
      pt={{
        base: 9,
        md: 0
      }}
      >
          <SlideFade in={run}>
          <Button ref={buttonEl} variant='ghostPink' to="/testimonials" leftIcon={<FontAwesomeIcon icon={faConciergeBell} />} as={Link}>
              What Our Customers Say
          </Button>
          </SlideFade>
        </Flex>
      </Flex>
      <div className="container-fluid">
      <Specials heading={data?.sanityHomePage?.saleHeader} description={data?.sanityHomePage?._rawSaleDescription}  image={salesImage ? salesImage : ''} />
</div>

      </>
  );
}

export default About;
