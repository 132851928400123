import React from 'react';

import { graphql } from 'gatsby';
import Layout from '../components/layout/layout';
import Seo from '../components/gatsby-starter/seo';
import About from '../components/About/About';
import Staff from '../components/Staff/Staff';
// import SocialMediaRow from "../components/About/SocialMediaRow/SocialMediaRow";

function IndexPage(props) {
  return (
    <Layout logo={props?.data?.sanityHomePage?.logo.asset} tagline={props?.data?.sanityHomePage?.tagline} {...props}>
      <Seo
        title="About"
        description="Learn about Louisville Laser and Spa Spokane"
      />
      <About  data={props.data} />
      {/* <SocialMediaRow /> */}
      <Staff data={props.data} />
    </Layout>
  );
}

export const query = graphql`
  {
    sanityHomePage {
      tagline
      logo {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: AUTO, width: 300)
        }
      }
      saleImage {
        asset {
          gatsbyImageData(placeholder: BLURRED)
          url
          id
        }
      }
      saleHeader
      _rawSaleDescription(resolveReferences: {maxDepth: 10})
    }
    allSanityHomePage {
      edges {
        node {
          professionalServicesImage {
            asset {
              gatsbyImageData(placeholder: BLURRED)
              url
              id
            }
          }
          homeHeader {
            asset {
              gatsbyImageData(placeholder: BLURRED)
              url
              id
            }
          }
          tagline
        }
      }
    }
    allSanityStaff(sort: {fields: rank, order: ASC}) {
      edges {
        node {
          id
          _rawBio(resolveReferences: { maxDepth: 10 })
          name
          image {
            asset {
              url
              id
              gatsbyImageData(placeholder: BLURRED, height: 200)
              metadata {
                dimensions {
                  _key
                  _type
                  height
                  width
                  aspectRatio
                }
                palette {
                  _key
                  _type
                }
                _key
                _type
              }
              id
              _id
              size
              source {
                url
                name
                _type
                _key
              }
            }
            hotspot {
              height
              width
              x
              y
              _type
              _key
            }
            _key
            _type
            crop {
              _key
              _type
              bottom
              left
              right
              top
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
