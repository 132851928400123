import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Button, Flex, Heading, SlideFade, Text } from '@chakra-ui/react';

function ProfServicesRow(props) {
  const buttonEl = useRef(null);
  const [run, setRun] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const el = buttonEl?.current;
      if (el) {
        if (el.getBoundingClientRect().bottom - window.innerHeight <= 0 && run !== true) {
          setRun(true);
        }
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [run]);

  return (
    <Flex flexDirection={{
      base: 'column',
      md: 'row'
    }} alignItems='center' py={'15vh'} justifyContent={'space-between'}>
      <Box pb={{ base: '5vh', md: '0' }} px={{ base: '0px', lg: '85px' }} flex={'0 0 50%'}>
        <Heading pb={3} color="gray.800" pl={0} as='h2' size='4xl'>Services</Heading>
        <Text fontSize={'xl'} pb={6}>Our Master Estheticians offer a full range of laser and facial rejuvenation treatments. Our staff will educate and introduce you to skincare products that will help you to achieve healthier skin.</Text>
        <SlideFade offsetY={80} in={run}>
          <Button _hover={{ color: 'pink.500', bg: 'gray.50' }} variant='solid' size='lg' leftIcon={<FontAwesomeIcon icon={faUserMd} />} style={{ visibility: run ? 'visible' : 'hidden' }} to="/services" ref={buttonEl} as={Link}>All Services</Button>
        </SlideFade>
      </Box>
      <Box px={{ base: '0', md: '16px', lg: '85px' }} pr={{ base: '12px', md: '16px', lg: '85px' }}>
          <GatsbyImage style={{
            height: '450px',
            borderRadius: '8px',
            boxShadow: '8px 8px 1px 0px #acdd6f, 12px 12px 10px 0px rgba(0,0,0,0.25)',
          }} image={props?.image?.asset?.gatsbyImageData} alt="professional performing laser and spa services" />
      </Box>
    </Flex>
  );
}

export default ProfServicesRow;
