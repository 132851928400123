import React, { useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons"
import StaffMember from "./StaffMember/StaffMember"
import Sidebar from "../layout/Sidebar/SideBar"
import Phone from "../contact/Phone/Phone"
import Hours from "../contact/Hours/Hours"
import RequestConsultationForm from "../Forms/RequestConsultation/RequestConsultation"
import Modal from "../UI/Dropdown/Modal/Modal"
import { Box, Button, Card, CardFooter, Flex, Heading } from "@chakra-ui/react"
import Email from "../contact/Email"

function Staff(props) {
  const [showModal, setShowModal] = useState(false)
  return (
    <Box pb={'10vh'} className="container-fluid">
      <Modal show={showModal} modalClosed={() => setShowModal(false)}>
        <RequestConsultationForm
          modalClosed={setShowModal}
          styling={{ maxWidth: "100%" }}
        />
      </Modal>
      <Box  pb='8vh' className="row d-block d-sm-flex">
        <div
          className="col d-flex flex-wrap justify-content-center"
        >

        </div>
      </Box>
      <Box flexDirection={{
        base: 'column-reverse',
        lg: 'unset'
      }} className="row">
        <div className="col-lg-3">
          <Sidebar styling={{ zIndex: 0 }} classes="sticky-top">
            <Card p={5}>
              <Phone />
              <Email />
              <Hours />
              <CardFooter px={'5px'}>

            <Button
              variant='pink'
              size={{
                base: 'lg',
                md: 'sm',
                lg: 'sm',
                xl: 'lg'
              }}
              leftIcon={<FontAwesomeIcon icon={faCalendarAlt} />}
              onClick={() => setShowModal(true)}
            >
               Request Consultation
            </Button>
              </CardFooter>
            </Card>
          </Sidebar>
        </div>
        <div className="col-lg-9 my-3">
          <Flex justifyContent='center' pb='5vh'>

        <Heading
            as='h2'
            size='4xl'
            color='gray.800'
            >
            Staff
          </Heading>
            </Flex>
          {props?.data?.allSanityStaff?.edges.map(staff => (
            <StaffMember
              key={staff?.node?.id}
              name={staff?.node?.name}
              bio={staff?.node?._rawBio}
              image={staff?.node?.image}
              
            />
          ))}
        </div>
      </Box>
    </Box>
  )
}

export default Staff
