import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import * as classes from "./Laser.module.css"
import { Heading } from "@chakra-ui/react"

function Laser(props) {
  return (
    <>
      <div className="row">
        <div className="col-sm-12 px-0">
          <div className={classes.VideoContainer}>
              <GatsbyImage alt='hero' className={classes.Video} image={props?.image?.asset?.gatsbyImageData} />
            <div className={classes.LaserPWrapper}>
              {/* <h2 className={classes.LaserTitle}>Laser</h2> */}
              <Heading
                size={{ base: "2xl", md: "4xl" }}
                css={{
                  position: "absolute",
                  zIndex: 1,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: 300,
                  letterSpacing: "10px",
                  fontFamily: "'Oswald', sans-serif",
                }}
                color="whiteAlpha.900"
                as="h1"
              >
                {/* Remove Hair Anywhere You Dare to Bare */}
              </Heading>{" "}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Laser
