// extracted by mini-css-extract-plugin
export var BigButton = "Laser-module--BigButton--93d15";
export var Button = "Laser-module--Button--6c352";
export var ButtonCol = "Laser-module--ButtonCol--f3236";
export var Icon = "Laser-module--Icon--d23c4";
export var LaserPWrapper = "Laser-module--LaserPWrapper--4dead";
export var LaserTitle = "Laser-module--LaserTitle--934bf";
export var Tagline = "Laser-module--Tagline--a2a98";
export var TaglineCol = "Laser-module--TaglineCol--dbf79";
export var Video = "Laser-module--Video--510fb";
export var VideoContainer = "Laser-module--VideoContainer--17636";