import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import BlockContent from '@sanity/block-content-to-react';
import {
    imageSerializer, createImgBuilder, youtubeSerializer, blockSerializerWithAlignment, sanityClient,
  } from 'jrts-sanity';

const client = sanityClient("zbi98nq2", "production", "true")

const builder = createImgBuilder(client)

const serializers = {
  types: {
    imageBlockContent: props => imageSerializer(props, builder),
    youtube: ({ node }) => youtubeSerializer(node),
    block: blockSerializerWithAlignment,
  },
}

function Specials(props) {
  return (
    <Flex flexDirection={{
      base: 'column',
      md: 'row'
    }} alignItems='center' pt={'15vh'} pb={0} justifyContent={'space-between'}>
      <Box pb={{ base: '5vh', md: '0' }} px={{ base: '12px', md: '16px', lg: '85px' }} pr={{ base: '12px', md: '16px', lg: '85px' }}>
          <GatsbyImage objectFit='contain' style={{
            //   height: '450px',
            maxHeight: '90vh',
              borderRadius: '8px',
            //   boxShadow: '8px 8px 1px 0px #acdd6f, 12px 12px 10px 0px rgba(0,0,0,0.25)',
            }} image={props?.image} alt="specials" />
      </Box>
        <Box px={{ base: '12px', lg: '0px' }} pr={{ base: '0px', lg: '85px'  }} flex={'0 0 50%'}>
            <Heading pb={3} color="gray.800" pl={0} as='h2' size='4xl'>{props.heading}</Heading>
            <Text fontSize={'xl'} pb={6}>
                <BlockContent blocks={props.description} serializers={serializers} />
            </Text>
        </Box>
    </Flex>
  );
}

export default Specials;
