import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Link } from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';

const Email = () => {
  const ref = useRef(null)
  const [width, setWidth] = useState(0);

  useEffect(() => {
    function resize() {
      const el = ref?.current;
      if (el) {
        setWidth(el?.getBoundingClientRect()?.width);
      }
    }

    resize();

    window.addEventListener('resize', resize);
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, [])

  return (
  <Box ref={ref} pb={4} w='inherit'>
    <Button w='inherit' variant='link' leftIcon={<EmailIcon />}>
      <Link textAlign='left' pr={7} w={`${width}px`} overflow='hidden' textOverflow='ellipsis' textTransform={'lowercase'} color="pink.500" href="mailto:support@louisvillelaserspokane.com">support@louisvillelaserspokane.com</Link>
    </Button>
  </Box>
)
}

export default Email;
